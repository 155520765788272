@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  overscroll-behavior: contain;
}

.loading {
  z-index: 10000;
  background: #fff;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist-footer-button {
  position: relative;
  height: 24px;
  color: #121212;
}

.wishlist-footer-button span {
  position: absolute;
  height: 24px;
  width: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 29px;
  text-align: center;
  vertical-align: middle;
}

.footerContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-width: 100vw;
  padding: 10px;
  padding-bottom: 14px;
  z-index: 1000;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 74.48%
  );
}

.footer {
  padding: 5px;
  border-radius: 30px;
  background: #f5f5f5;
  transition: height 500ms ease;
  overflow: hidden;
}

.footer-regular {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.footer-settings-enter,
.footer-regular-enter {
  opacity: 0;
}

.footer-settings-enter-active,
.footer-regular-enter-active {
  transition: all 500ms ease;
  opacity: 1;
}

.footer-settings-exit,
.footer-regular-exit {
  opacity: 1;
}

.footer-settings-exit-active,
.footer-regular-exit-active {
  transition: all 500ms ease;
  opacity: 0;
}

.footer-settings-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-settings-body {
  height: 70vh;
  overflow: scroll;
}

.header {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.header.backgroundImage {
  height: 80px;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 500px) {
  .header.backgroundImage {
    height: 60px;
  }

  main {
    overflow: hidden;
  }
}

.languageToggle {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.languageElement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.languageElement.active {
  background: #f5f5f5;
}

.languageElement svg:nth-child(1) {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.preferenceElement {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.preferenceElement p {
  flex-grow: 1;
}

.preferenceElement svg:nth-child(1) {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.categoryItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #f0f0f0;
  margin-bottom: 20px;
  border-radius: 4px;
}

.subCategoryItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #f0f0f0;
  border-radius: 2px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

.subCategoryItem.noImage {
  height: 100px;
  background-color: #000;
}

.subCategoryItem p {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.4em;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
}

.categoryHeader svg {
  height: 20px;
  width: 20px;
  color: #9e9e9e;
}

.mealContainer {
  margin: 0px -15px;
  padding: 30px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 2px;
  border-bottom: 2px solid #f7f7f7;
  position: relative;
}

.mealContainerInnerAllergens {
  position: absolute;
  bottom: 30px;
}

.mealContainer:hover {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.08);
}

.mealContainer > .mealContainerImage {
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin-right: 10px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
}

.mealContainerInner {
  flex-grow: 10;
}

.mealHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.mealTag {
  padding: 4px;
  display: inline-block;
  background: #ce9933;
  color: #fff;
  font-size: 11px;
  line-height: 11px;
  font-weight: bolder;
  text-transform: uppercase;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.mealHeader h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
}

.mealHeader div {
  flex-grow: 1;
}

.mealHeader svg {
  margin-left: 5px;
}

.mealDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  max-width: 90%;
}

.meal-item-option {
  margin: 5px 0;
}

.meal-item-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meal-item-option-header h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
}

.allergenModal {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}

.allergen-modal-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.allergen-modal-item > p {
  margin-right: 5px;
  width: 20px;
}

.cart-button {
  color: #121212;
  padding: 6px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 20px;
  height: 36px;
  background: #000;
  color: #fff;
  border-width: 0px;
  position: relative;
  z-index: 0;
}

.cart-button-cover {
  content: '';
  width: 36px;
  height: 36px;
  background: #000;
  border-radius: 20px;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.cart-button svg {
  z-index: 1;
  position: relative;
}

.cart-button .clicked {
  position: absolute;
  width: 100%;
  text-align: center;
  border-radius: 2px;
  background: #e1e1e1;
  z-index: -1;
  top: 10px;
  left: 0;
  transform: translateY(0px);
}

.cart-button.active {
  color: #fff;
  padding: 4px 6px;
  background: #121212;
  appearance: none;
  border-radius: 0px;
  border: 1px solid #121212;
}

.cart-button:focus,
.cart-button:hover {
  outline: none;
  opacity: 0.8;
}

.cart-button:active .clicked {
  transform: translateY(-30px);
  transition: all ease 200ms;
}

.cart-footer-button {
  position: relative;
  height: 24px;
  color: #121212;
}

.cart-footer-button span {
  position: absolute;
  height: 24px;
  width: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
}

.category-item-icon {
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.category-item-icon div {
  height: 80px;
  width: 80px;
  min-width: 80px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.category-item-icon h4 {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 30px;
  text-transform: uppercase;
  color: #000000;
  flex-grow: 1;
}

.main-page .category-list {
  grid-area: sub-header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f0f0f0;
  padding: 10px 50px;
  padding-right: 100px;
  position: sticky;
  overflow: hidden;
  height: 50px;
  top: 0px;
  z-index: 10;
  max-width: 100vw;
}

.main-page .category-list .search {
  position: absolute;
  left: 15px;
  top: 0px;
  background: #f0f0f0;
  z-index: 1;
  transition: width 500ms ease;
  width: 20px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}

.main-page .category-list .search.active {
  width: calc(100% - 30px);
}

.category-list-scroll {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 10px 0px;
  transition: all 400ms ease;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.category-list-scroll::-webkit-scrollbar {
  display: none;
}

.main-page .category-list .settings {
  position: absolute;
  right: 65px;
  top: 0px;
  background: #f0f0f0;
  z-index: 1;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.main-page.open .settings {
  right: 15px;
}

.main-page .category-list .cartToggle {
  position: absolute;
  right: 20px;
  top: 0px;
  background: #f0f0f0;
  z-index: 1;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

@media screen and (max-width: 760px) {
  .main-page .category-list .settings {
    display: none;
  }

  .main-page .category-list .cartToggle {
    display: none;
  }

  .main-page .category-list {
    grid-area: sub-header;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    position: sticky;
    overflow: hidden;
    height: 50px;
    top: 0px;
    z-index: 10;
    max-width: 100vw;
  }
}

.main-page .category-list .settings svg {
  color: #121212;
}

.main-page .category-list .cartToggle svg {
  color: #121212;
}

.category-list-item {
  margin-right: 15px;
  font-size: 18px;
  line-height: 21px;
  color: rgba(18, 18, 18, 0.6);
  transition: color 500ms ease;
  cursor: pointer;
}

.category-list-item:hover {
  color: rgba(18, 18, 18, 1);
}

.category-list-item.active {
  font-weight: 500;
  color: rgba(18, 18, 18, 1);
}

.sub-header {
  grid-area: sub-header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f0f0f0;
  padding: 10px;
  position: sticky;
  overflow: hidden;
  height: 50px;
  top: 0px;
  z-index: 10;
}

.sub-header span {
  font-weight: 500;
  color: #121212;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

main > div {
  max-width: 700px;
  padding: 15px;
  margin: 0 auto;
}

@media screen and (max-width: 759px) {
  aside {
    padding: 20px;
  }
}

@media screen and (min-width: 760px) {
  section {
    display: grid;
    height: calc(100vh - 80px);
    width: calc(100vw);
    grid-template-columns: auto 340px;
    grid-template-rows: 50px auto;
    grid-template-areas:
      'sub-header cart-header'
      'meal-wrapper cart';
  }

  section.main-page {
    grid-template-columns: auto 0px;
  }

  section.main-page.open {
    grid-template-columns: auto 340px;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .header img {
    height: 50px;
  }

  .footerContainer {
    display: none;
  }

  .cart-header {
    grid-area: cart-header;
    background: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-page .cart-header {
    display: none;
  }

  .cart-header .close {
    display: none;
  }

  .main-page .cart-header .close {
    display: block;
    position: absolute;
    right: 10px;
  }

  .cart-header span {
    font-weight: 500;
    color: #121212;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }

  main {
    grid-area: meal-wrapper;
    overflow: scroll;
    scroll-behavior: smooth;
  }

  main > div {
    max-width: 700px;
    padding: 15px;
    margin: 0 auto;
  }

  aside {
    grid-area: cart;
    background: #f7f7f7;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .main-page aside {
    display: none;
  }

  .open .cart-header,
  .open aside {
    display: flex;
  }

  .categoryHeader h2 {
    font-size: 28px !important;
    line-height: 33px !important;
  }

  .footer-settings {
    width: 300px;
    height: 400px;
    padding: 5px;
  }

  .footer-settings-body {
    height: 346px;
    overflow: scroll;
  }

  .MuiPopover-root {
    background: rgba(0, 0, 0, 0.1);
  }
}

.empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e1e1e1;
  flex-direction: column;
  padding: 20px;
}

aside > hr {
  margin-bottom: 10px !important;
}

.cart-sum-container {
  flex-grow: 10;
}

.cart-sum-container > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.empty-cart span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

@media screen and (min-width: 901px) {
  section.open {
    grid-template-columns: auto 400px;
  }
}

.cart-item {
  margin-bottom: 10px;
}

.cart-item > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cart-item-title,
.cart-item-amount,
.cart-item-price {
  font-size: 16px;
}

.cart-item-amount {
  margin-right: 15px;
}

.cart-item-actions {
  justify-content: space-between !important;
}

.cart-item-title {
  flex-grow: 10;
  font-weight: bold;
}

.tip-container {
  padding: 15px;
  background: #303c42;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #fff;
}

.tip-container h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  margin-bottom: 10px;
}

.tip-container > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tip-container button {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #303c42;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.3);
  flex-grow: 1;
  padding: 8px 10px;
  border-radius: 2px;
  appearance: none;
  border: none;
  margin-right: 10px;
}

.tip-container button:active {
  background: rgba(255, 255, 255, 0.8);
  appearance: none;
  border: none;
  outline: none;
}

.tip-container button:focus {
  appearance: none;
  border: none;
  outline: none;
}

.tip-container button:nth-child(2) {
  background: rgba(255, 255, 255, 0.4);
}

.tip-container button:nth-child(3) {
  background: rgba(255, 255, 255, 0.5);
  margin-right: 0px;
}

.tip-container button:last-of-type {
  margin-right: 0px;
}

.tip-container form > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #303c42;
  background: rgba(255, 255, 255, 0.3);
  flex-grow: 1;
  padding: 4px 10px;
  border-radius: 2px;
  appearance: none;
  border: none;
  margin-right: 10px;
  flex-grow: none;
  width: 66%;
}

.tip-container form button {
  justify-content: center;
  flex-grow: none;
  width: 33%;
}

.termsAndConditions {
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  position: relative;
  overflow-x: hidden;
}

.termsAndConditions h2,
.termsAndConditions h3,
.termsAndConditions p {
  margin: 10px 0 20px;
}

.termsAndConditions #termsAndConditionsClose {
  position: absolute;
  right: 10px;
  top: 10px;
}

.termsAndConditionsContainer {
  padding: 15px;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  z-index: 1001;
}

.category-item-height-small {
  height: 80px;
}

.category-item-height-normal {
  height: 100px;
}

.category-item-height-high {
  height: 120px;
}

.categoryItem3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.categoryItem3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.categoryItem3 .categoryItem3-img {
  height: 100px;
  min-width: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.categoryItem3 h3 {
  font-weight: 700;
  font-size: 2rem;
}

@media screen and (max-width: 500px) {
  .categoryItem3 {
    flex-direction: column;
  }
}

.imageModal {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  padding: 20px;
  top: 0;
  left: 0;
  z-index: 10000;
}

.imageModal img {
  width: 600px;
  height: auto;
  max-width: 100%;
}

.imageModal button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.option-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .option-modal-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .option-modal-actions button {
    width: 100%;
  }

  .option-modal-actions > div {
    width: 100%;
    margin-bottom: 10px;
  }
}

.show_more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-top: 16px;
  width: 100%;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  transform: translateY(-30px);
}
