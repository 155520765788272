.highlighted-slider {
  margin-bottom: 20px;
  height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.highlighted-slider .highlighted-slider-card {
  background-position: center;
  background-size: cover;
  padding: 10px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}

.highlighted-slider .animated-title-container {
  position: absolute;
  bottom: 50px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  height: 26px;
  width: calc(100% - 20px);
}

.highlighted-slider .animated-title {
  overflow: hidden;
}

.highlighted-slider .animated-title p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  line-height: 26px;
  color: #333333;
}

.highlighted-slider .highlight-dot-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.highlighted-slider .highlight-dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 5px;
  transition: all 500ms ease;
  background: #333333;
  opacity: 0.5;
}

.highlighted-slider .highlight-dot.active {
  width: 20px;
  opacity: 1;
}

.highlighted-slider .highlighted-tags {
  display: inline;
  padding: 4px;
  font-size: 11px;
  color: #fff;
  background: #333333;
}
