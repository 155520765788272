.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.purchaseInfo {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.purchaseInfo > div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.purchaseInfo ul {
  flex-grow: 1;
  padding: 0;
}

.formContainer {
  width: 100%;
  padding: 10px;
}

.pillContainer {
  margin: 20px 0;
}

.itemRight {
  flex-grow: 0;
  text-align: right;
}

.loading {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successContainer {
  min-height: 200px;
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.successContainer * {
  text-align: center;
  margin: 10px 0 !important;
}

.successContainer svg {
  width: 100px;
  height: 100px;
  color: #4bb543;
}

.errorContainer {
  min-height: 200px;
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.errorContainer * {
  text-align: center;
  margin: 10px 0 !important;
}

.errorContainer svg {
  width: 100px;
  height: 100px;
  color: #dc2538;
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .purchaseInfo {
    width: 100%;
  }

  .pillContainer button {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
