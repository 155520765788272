.first-steps-modal {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
}

.first-steps-modal h3 {
  font-size: 24px;
  line-height: 100%;
  font-weight: bold;
  color: #121212;
  margin-bottom: 10px;
}

.first-steps-modal h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #121212;
  margin-bottom: 40px;
}

.first-steps-modal-body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.first-steps-modal-body hr {
  margin: 20px 0;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.first-steps-modal-body .or {
  display: inline-block;
  transform: translateY(-34px);
  background: white;
  color: #e0e0e0;
  padding: 3px;
  font-weight: 500;
}

.first-steps-modal-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.first-steps-modal .dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: rgba(12, 12, 12, 0.5);
  transition: background 500ms ease;
}

.first-steps-modal .dot:first-child {
  margin-right: 10px;
}

.first-steps-modal .dot.active {
  background: rgba(12, 12, 12, 1);
}

.DayPickerContainer {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DayPickerContainer > div {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.CalendarDay__highlighted_calendar {
  background: white !important;
  opacity: 0.3;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #333 !important;
  border: 1px double #333 !important;
  color: #fff;
}

.first-steps-close {
  position: absolute !important;
  top: 5px;
  right: 5px;
  color: #121212;
}
