.ad-slider {
  margin-bottom: 20px;
  height: 170px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.ad-slider .animated-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ad-slider .ad-slider-card {
  background-position: center;
  background-size: cover;
  padding: 10px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
}

.ad-slider .animated-title-container {
  text-align: center;
  display: flex;
  justify-content: space-between;
  height: 26px;
  width: calc(100% - 20px);
}

.ad-slider .animated-title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ad-slider .animated-title .animated-button {
  font-size: 18px;
  font-weight: 400;
  padding: 4px 8px;
  border: 2px solid #fff;
  margin-top: 10px;
  cursor: pointer;
  width: max-content;
  border-radius: 2px;
}

.ad-slider .animated-title .animated-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.ad-slider .animated-title p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  line-height: 26px;
  width: 100%;
  color: #fff;
}

.ad-slider .ad-dot-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.ad-slider .ad-dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 5px;
  transition: all 500ms ease;
  background: #fff;
  opacity: 0.5;
}

.ad-slider .ad-dot.active {
  width: 20px;
  opacity: 1;
}
