.payment-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.payment-card {
  height: 100px;
  width: 100px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-card span {
  font-weight: 500;
  font-size: 18px;
  opacity: 1;
  display: inline-block;
  z-index: 1;
}

.payment-card span::before {
  font-weight: 900;
  font-size: 40px;
  color: rgba(12, 12, 12, 0.3);
  position: absolute;
  content: attr(label);
  top: 40px;
  left: 30px;
  z-index: -1;
  white-space: nowrap;
}

.selected span::before {
  color: #6fcf97;
}

.payment-card.selected {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
}

.payment-card img {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
}

.voucher-container {
  margin-bottom: 10px;
}

.voucher-container h3 {
  margin-bottom: 10px;
}

.voucher-container p {
  margin-bottom: 10px;
  opacity: 0.83;
}

.voucher-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
